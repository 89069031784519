import React from "react";
import { Helmet } from "react-helmet";
import { Heading } from "@chakra-ui/react";

import Layout from "../../components/Layout";
import Courses from "../../components/Courses";
import Container from "../../components/Container";

const CoachPage = ({ ...props }) => (
  <>
    <Helmet>
      <title>Kurser</title>
    </Helmet>
    <Layout {...props}>
      <Heading as="h1" my="3rem" size="lg" textAlign="center">
        Kurser
      </Heading>
      <Container pb="3">
        <Courses />
      </Container>
    </Layout>
  </>
);

export default CoachPage;
