import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import { Grid, GridItem, Box, Text } from "@chakra-ui/react";

import PreviewCompatibleImageFluid from "./PreviewCompatibleImageFluid";

const Courses = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <Grid gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr" gap={6} my={6}>
      {posts?.map(({ node: post }) => (
        <Course post={post} key={post.id} />
      ))}
    </Grid>
  );
};

const Course = ({ post }) => {
  return (
    <GridItem colSpan={[6, 3, 2]}>
      <Link to={post.fields.slug}>
        <Box as="article" w="100%" borderWidth="1px" borderRadius="lg" overflow="hidden">
          <PreviewCompatibleImageFluid
            imageInfo={{
              image: post.frontmatter.featuredimage,
              alt: `Image thumbnail for post ${post.frontmatter.title}`,
            }}
          />

          <Box p="6">
            <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
              {post.frontmatter.title}
            </Box>
            <Box>
              <Text>{post.excerpt}</Text>
            </Box>
            <Box fontWeight="light">{post.frontmatter.date}</Box>
          </Box>
        </Box>
      </Link>
    </GridItem>
  );
};

Courses.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query CoursesQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "course-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 100)
              id
              html
              fields {
                slug
              }
              frontmatter {
                templateKey
                title
                templateKey
                date(formatString: "D MMMM YYYY", locale: "SV")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Courses data={data} count={count} />}
  />
);
